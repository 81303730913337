import React from "react";
import {
  IonContent,
  IonPage,
  IonSpinner,
} from "@ionic/react";

const SplashScreen: React.FC = () => {
  return (
    <IonPage>
      <IonContent
        fullscreen={true}
        className="ion-justify-content-center ion-align-items-center"
      >
        <IonSpinner />
      </IonContent>
    </IonPage>
  );
};

export default SplashScreen;
