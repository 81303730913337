import {
  IonButton,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonToggle,
  useIonToast,
} from "@ionic/react";
import { AxiosError } from "axios";
import { format, fromUnixTime } from "date-fns";
import { ReactNode, useState } from "react";
import useDeviceAttributes from "../../../services/hooks/device/useDeviceAttributes";
import useDeviceSettingLocal, {
  RelayDeviceSettingKey,
} from "../../../services/hooks/device/useDeviceSettingLocal";
import { sendOneWayRPCRequest } from "../../../services/thingsboard_client/rpc_v_2_controller";
import SkeletonCardButton from "../../SkeletonCardButton/SkeletonCardButton";

interface RelayDeviceProps {
  deviceId: string;
  relayKey: string;
}

const RelayToggle: React.FC<RelayDeviceProps> = ({ deviceId, relayKey }) => {
  const [present] = useIonToast();
  const [isSendingRpc, setIsSendingRpc] = useState(false);
  const { data: attributes, isLoading: isLoadingAttributes } =
    useDeviceAttributes(deviceId, undefined, [relayKey, "active"]);
  const { setting } = useDeviceSettingLocal<"relay">(deviceId, "relay");

  if (!attributes) {
    return <SkeletonCardButton />;
  }

  const attribute = attributes.find((attr) => attr.key === relayKey);
  const activeAttribute = attributes.find((attr) => attr.key === "active");

  const relayPin = parseInt(
    attribute?.key?.replace("GPIO_", "") || relayKey.replace("GPIO_", "")
  );
  const relayStatus = attribute ? (attribute.value as number) === 1 : false;
  const activeStatus = activeAttribute?.value;

  const handleSwitch = (pin: number, value: boolean) => {
    setIsSendingRpc(true);
    sendOneWayRPCRequest(deviceId, "setPin", {
      pin: pin,
      value: value ? 1 : 0,
    })
      .then((res) => {
        present({
          message: `Đã gửi lệnh điều khiển`,
          duration: 1500,
        });
      })
      .catch((error: AxiosError) => {
        present({
          message: `Điều khiển thất bại. Code: ${error.response?.status}`,
          duration: 1500,
        });
      })
      .finally(() => setIsSendingRpc(false));
  };

  const name =
    setting.names[attribute?.key as RelayDeviceSettingKey] ||
    `Relay ${relayPin}`;

  let content: ReactNode = <IonSpinner />;

  if (!isLoadingAttributes) {
    content = (
      <IonToggle
        checked={relayStatus}
        onClick={(event) => handleSwitch(relayPin, !relayStatus)}
        disabled={!activeStatus}
      ></IonToggle>
    );
  }

  const datetime = attribute
    ? fromUnixTime(attribute.lastUpdateTs / 1000)
    : null;

  return (
    <IonButton
      fill="solid"
      color="light"
      disabled={!activeStatus}
      style={{ width: "100%", height: "128px" }}
    >
      <div
        className="flex flex-column justify-between items-center "
        style={{ width: "100%", height: "100%", padding: "1.1em 0" }}
      >
        <div className="w-full truncat txt-left">{name}</div>
        <div className="h2 bold">{content}</div>
        <div className="w-full flex justify-between">
          <IonText color={"medium"}>
            {datetime ? format(datetime, "HH:mm") : "--"}
          </IonText>
          <IonText color="medium">
            {datetime ? format(datetime, "dd/MM") : "--"}
          </IonText>
        </div>
      </div>
    </IonButton>
  );
};

export default RelayToggle;
