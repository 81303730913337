import { useStickyState } from "../../common/hooks/useStickyState";
import { log } from "../logging/logging";
import { ITokens, refreshTokens } from "./authentication";
import { LOCAL_TOKENS_KEY } from "./const";

export const useToken = () => {
  const [tokens, setTokens] = useStickyState({}, LOCAL_TOKENS_KEY);

  const refreshToken = () => {
    if (tokens.token !== undefined && tokens.refreshToken !== undefined) {
      refreshTokens(tokens.refreshToken)
        .then((res) => {
          const newTokens: ITokens = res.data;
          setTokens(newTokens);
        })
        .catch((error) => {
          log(error);
          setTokens({});
        });
    }
  };

  return { token: tokens.token as string | undefined, refreshToken };
};
