import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useState } from "react";
import Header from "../../components/Header/Header";
import FormModal from "../../components/ScheduleItem/FormModal";
import ScheduleForm from "../../components/ScheduleItem/ScheduleForm";
import ScheduleItem from "../../components/ScheduleItem/ScheduleItem";
import useSchedulers from "../../services/hooks/scheduler/useSchedulers";
import { Scheduler } from "../../services/schedulers";
import { useUser } from "../../services/useUser";

type SchedulersPageProps = {};

const SchedulersPage = (props: SchedulersPageProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const {
    data: schedulers,
    create,
    update,
    remove,
  } = useSchedulers(user?.id);

  const onCreate = (scheduler: Scheduler) => {
    create(scheduler);
    setIsOpen(false);
  };
  const onUpdate = (scheduler: Scheduler) => {
    update(scheduler);
    setIsOpen(false);
  };
  const onRemove = (id: string) => {
    remove(id);
    setIsOpen(false);
  };

  return (
    <IonPage>
      <Header
        buttonEnd={
          <IonButton onClick={() => setIsOpen(true)} slot="end" fill="clear">
            <IonIcon slot="icon-only" icon={add} />
          </IonButton>
        }
      />

      <IonContent fullscreen>
        <IonList>
          {schedulers.map((scheduler) => (
            <IonItem lines="none" key={scheduler.id}>
              <ScheduleItem scheduler={scheduler} onEditSubmit={onUpdate} />
            </IonItem>
          ))}
        </IonList>

        <FormModal
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          title="Thêm hẹn giờ"
          form={
            <ScheduleForm
              onCreate={onCreate}
              onUpdate={onUpdate}
              onRemove={onRemove}
            />
          }
        />
      </IonContent>
    </IonPage>
  );
};

export default SchedulersPage;
