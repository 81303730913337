import {
  IonAvatar,
  IonContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonText,
} from "@ionic/react";
import { useHistory, useRouteMatch } from "react-router";
import { Container } from "../components/Container/Container";
import Header, { ContentHeader } from "../components/Header/Header";
import { logout } from "../services/authentication/authentication";
import { useUser } from "../services/useUser";
import { getPageTitle } from "../utils/app_page_helpers";

const ProfilePage: React.FC = () => {
  const router = useRouteMatch();
  const pageName = getPageTitle(router.path);

  const { user, isLoading, isError } = useUser();

  const history = useHistory();
  const handleLogout = () => {
    logout()
      .then((res) => {
        console.log("Logout successfully");
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        localStorage.clear();
        history.replace("/login");
      });
  };

  return (
    <IonPage>
      <Header />

      <IonContent fullscreen>
        <ContentHeader />
        <Container>
          <div>
            <IonAvatar>
              <img src="assets/images/avatar-default.jpg" alt="user avatar" />
            </IonAvatar>
            <IonText color="primary">
              <h4>{`${user?.firstName || ""} ${user?.lastName || ""}`}</h4>
            </IonText>
            <div></div>
          </div>
          <IonList>
            <IonItemDivider className="ion-no-padding">
              <IonLabel>Basic Info</IonLabel>
            </IonItemDivider>
            <IonItem className="ion-no-padding">
              <IonLabel>Email</IonLabel>
              <IonLabel>{user?.email || ""}</IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>Name</IonLabel>
              <IonLabel>{`${user?.firstName || ""} ${
                user?.lastName || ""
              }`}</IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>Authority</IonLabel>
              <IonLabel>{user?.authority || ""}</IonLabel>
            </IonItem>

            <IonItemDivider className="ion-no-padding">
              <IonLabel>Additional Info</IonLabel>
            </IonItemDivider>
            <IonItem className="ion-no-padding">
              <IonLabel>Last login</IonLabel>
              <IonLabel>{user?.additionalInfo.lastLoginTs || ""}</IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>Default dashboard fullscreen</IonLabel>
              <IonLabel>
                {user?.additionalInfo.defaultDashboardFullscreen
                  ? "Enabled"
                  : "Disabled"}
              </IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>Default dashboard ID</IonLabel>
              <IonLabel>
                {user?.additionalInfo.defaultDashboardId || ""}
              </IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>Fail login attempts</IonLabel>
              <IonLabel>
                {user?.additionalInfo.failedLoginAttempts || 0}
              </IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>Home dashboard hide toolbar</IonLabel>
              <IonLabel>
                {user?.additionalInfo.homeDashboardHideToolbar
                  ? "Enabled"
                  : "Disabled"}
              </IonLabel>
            </IonItem>

            <IonItemDivider className="ion-no-padding">
              <IonLabel>Tenant</IonLabel>
            </IonItemDivider>
            <IonItem className="ion-no-padding">
              <IonLabel>Entity type</IonLabel>
              <IonLabel>{user?.tenantId.entityType || ""}</IonLabel>
            </IonItem>
            <IonItem className="ion-no-padding">
              <IonLabel>ID</IonLabel>
              <IonLabel>{user?.tenantId.id || ""}</IonLabel>
            </IonItem>

            <IonItemDivider className="ion-no-padding">
              <IonLabel>Logout</IonLabel>
            </IonItemDivider>
            <IonItem className="ion-no-padding">
              <IonLabel onClick={handleLogout}>Logout</IonLabel>
            </IonItem>
          </IonList>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default ProfilePage;
