import useSWR from "swr";
import { EntityType } from "../../../common/types/entity";
import { TsValue } from "../../../common/types/timeseries";
import { fetcher } from "../../../utils/fetcher";

const useGetLatestTimeseries = (
  entityType: EntityType,
  entityId: string,
  keys?: string[],
  useStrictDataTypes?: boolean,
  configs?: {
    refreshInterval: number;
  }
) => {
  const _keys = keys && keys.length > 0 ? `&keys=${keys.join(",")}` : "";
  const _useStrictDataTypes = useStrictDataTypes || true;

  const api = `/api/plugins/telemetry/${entityType}/${entityId}/values/timeseries?useStrictDataTypes=${_useStrictDataTypes}${_keys}`;

  const { data, error } = useSWR<Record<string, TsValue[]>>(
    entityId !== undefined ? api : null,
    fetcher,
    configs
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useGetLatestTimeseries;
