import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
} from "@ionic/react";
import { ReactNode } from "react";

type FormModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  form: ReactNode;
  title?: string;
};

const FormModal = ({ isOpen, onDismiss, form, title }: FormModalProps) => {
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>Hủy bỏ</IonButton>
          </IonButtons>
          <IonTitle>{title ? title : null}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{form}</IonContent>
    </IonModal>
  );
};

export default FormModal;
