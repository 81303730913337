import { EntityId } from "../../../common/types/entity";
import useUserSetting from "../user/useUserSetting";

const SCHEDULER_SETTING_KEY = "schedulers";

export const AVAILABLE_ACTIONS = {
  send_notitication: "Gửi thông báo",
  control_on: "Điều khiển bật",
  control_off: "Điều khiển tắt",
};

export const AVAILABLE_EVERY_UNIT = {
  hour: "Giờ",
  day: "Ngày",
};

export type AvailableActions = keyof typeof AVAILABLE_ACTIONS;
export type AvailableEveryUnit = keyof typeof AVAILABLE_EVERY_UNIT;

export type Scheduler = {
  id?: string;
  name?: string;
  runAt?: string; // work with every day. Format: 12:00
  every?: number;
  everyUnit?: AvailableEveryUnit;
  duringMinutes?: number; // minutes
  action: AvailableActions;
  deviceId?: string;
  relayGpio?: number;
  enable?: boolean;
};

/**
 *
 * @returns id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
 */
const guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

const useSchedulers = (userId?: EntityId) => {
  const { data, update: updateSetting } = useUserSetting(userId, [
    SCHEDULER_SETTING_KEY,
  ]);
  const schedulers =
    data && data.length > 0 ? (data[0].value as Scheduler[]) : [];

  const create = (obj: Scheduler) => {
    obj.id = guid();
    updateSetting(SCHEDULER_SETTING_KEY, [...schedulers, obj]);
  };

  const update = (obj: Scheduler) => {
    const index = schedulers.findIndex((sch) => sch.id === obj.id);
    if (index === -1) return; // Not found

    const existingScheduler = schedulers[index];
    const updatedScheduler = { ...existingScheduler, ...obj };
    schedulers[index] = updatedScheduler;
    updateSetting(SCHEDULER_SETTING_KEY, [...schedulers]);
  };

  const remove = (id: string) => {
    const index = schedulers.findIndex((sch) => sch.id === id);
    if (index === -1) return; // Not found

    schedulers.splice(index, 1);
    updateSetting(SCHEDULER_SETTING_KEY, [...schedulers]);
  };

  return { data: schedulers, create, update, remove };
};

export default useSchedulers;
