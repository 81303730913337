import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import { EntityId } from "../common/types/entity";
import { TSortOrder } from "../common/types/sort";
import { fetcher } from "../utils/fetcher";
import { Attribute } from "../common/types/attribute";

export const useAttributes = (
  entity: EntityId,
  configs?: AxiosRequestConfig,
  keys?: string[]
) => {
  const mergedConfigs = {
    ...configs,
    ...{ params: { keys: keys ? keys.join(",") : undefined } },
  };
  const key = [
    `/api/plugins/telemetry/${entity.entityType}/${entity.id}/values/attributes`,
    mergedConfigs,
  ];
  const { data, error } = useSWR<Attribute[]>(key, fetcher);
  return {
    attributes: data,
    isLoading: !error && !data,
    isError: error,
    key: key,
  };
};

/* Get latest timeseries value */
export interface ILatestTimeseries {
  [key: string]: { value: any; ts: Number }[];
}
export const useLatestTimeseries = (
  entity: EntityId,
  keys?: string[],
  configs?: AxiosRequestConfig
) => {
  const mergedConfigs = {
    ...configs,
    ...{
      params: {
        useStrictDataTypes: true,
        keys: keys ? keys.join(",") : undefined,
      },
    },
  };
  const { data, error } = useSWR<ILatestTimeseries>(
    [
      `/api/plugins/telemetry/${entity.entityType}/${entity.id}/values/timeseries`,
      mergedConfigs,
    ],
    fetcher
  );
  return {
    latestTimeseries: data,
    isLoading: !error && !data,
    isError: error,
  };
};

/* Get timeseries data */
export interface ITimeseries {}
export const useTimeseries = (
  entity: EntityId,
  keys: string[],
  startTs: Number,
  endTs: Number,
  interval?: Number,
  limit?: number,
  agg?: string,
  orderBy?: TSortOrder,
  configs?: AxiosRequestConfig
) => {
  const mergedConfigs = {
    ...configs,
    ...{
      params: {
        keys,
        startTs,
        endTs,
        interval,
        limit,
        agg,
        orderBy,
        useStrictDataTypes: true,
      },
    },
  };
  const { data, error } = useSWR<ILatestTimeseries[]>(
    [
      `/api/plugins/telemetry/${entity.entityType}/${entity.id}/values/timeseries`,
      mergedConfigs,
    ],
    fetcher
  );
  return {
    value: data,
    isLoading: !error && !data,
    isError: error,
  };
};
