import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonToggle,
} from "@ionic/react";
import {
  calendar,
  gitBranch,
  gitCommit,
  hourglass,
  time,
} from "ionicons/icons";
import { useState } from "react";
import { useDevice } from "../../services/hooks/device/useDevices";
import { Scheduler } from "../../services/schedulers";
import { useRelay } from "../../services/useRelays";
import FormModal from "./FormModal";
import ScheduleForm from "./ScheduleForm";

type ScheduleItemProps = {
  scheduler: Scheduler;
  onEditSubmit?: (scheduler: Scheduler) => void;
};

// name={scheduler.name}
//                 options={{ runAt: parse(scheduler.runAt, "HH:mm", new Date()) }}
const parseRepeatEvery = (
  every: string | number,
  everyUnit?: string
): string => {
  everyUnit = everyUnit === "day" ? "ngày" : "giờ";

  if (typeof every === "number") {
    return every === 1 ? `Mỗi ${everyUnit}` : `Mỗi ${every} ${everyUnit}`;
  }

  const regex = /[^0-9]/g;
  const numberPart = every.replaceAll(regex, "");
  const everyNumber = Number.parseInt(numberPart);
  return everyNumber === 1 ? `Mỗi ${everyUnit}` : `Mỗi ${every} ${everyUnit}`;
};

const ScheduleItem = ({ scheduler, onEditSubmit }: ScheduleItemProps) => {
  const { data: device } = useDevice(scheduler.deviceId);
  const { relay } = useRelay(
    scheduler.deviceId || "",
    scheduler.relayGpio || 1
  );

  const [isOpen, setIsOpen] = useState(false);

  const name = scheduler.name;
  const runAt = scheduler.runAt ? scheduler.runAt : "--:--";
  const repeat = scheduler.every
    ? parseRepeatEvery(scheduler.every, scheduler.everyUnit)
    : "--:--";

  const handleToggleScheduleItem = () => {
    scheduler.enable = !scheduler.enable;
    onEditSubmit && onEditSubmit(scheduler);
  };

  const handleSubmit = (updatedScheduler: Scheduler) => {
    setIsOpen(false);
    onEditSubmit && onEditSubmit(updatedScheduler);
  };

  return (
    <IonCard className="w-full">
      <IonCardHeader>
        <IonItem lines="none">
          <IonLabel className="txt-bold">{name}</IonLabel>
          <IonToggle
            slot="end"
            onClick={handleToggleScheduleItem}
            checked={scheduler.enable}
          />
        </IonItem>
      </IonCardHeader>

      <IonCardContent>
        <IonItem lines="none" onClick={() => setIsOpen(true)} detail={true}>
          <IonList>
            {scheduler.deviceId ? (
              <IonItem lines="none" className="ion-no-padding">
                <IonIcon icon={gitBranch} slot="start" />
                <IonLabel>{device?.name || "--"}</IonLabel>
              </IonItem>
            ) : null}

            {scheduler.relayGpio ? (
              <IonItem lines="none" className="ion-no-padding">
                <IonIcon icon={gitCommit} slot="start" />
                <IonLabel>{relay.name}</IonLabel>
              </IonItem>
            ) : null}

            <IonItem lines="none" className="ion-no-padding">
              <IonIcon icon={calendar} slot="start" />
              <IonLabel>{repeat}</IonLabel>
            </IonItem>

            {scheduler.everyUnit === "day" ? (
              <IonItem lines="none" className="ion-no-padding">
                <IonIcon icon={time} slot="start" />
                <IonLabel>Vào lúc {runAt}</IonLabel>
              </IonItem>
            ) : null}

            {scheduler.duringMinutes !== 0 ? (
              <IonItem lines="none" className="ion-no-padding">
                <IonIcon icon={hourglass} slot="start" />
                <IonLabel>Trong vòng {scheduler.duringMinutes} phút</IonLabel>
              </IonItem>
            ) : null}
          </IonList>
        </IonItem>

        <FormModal
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          title="Chỉnh sửa hẹn giờ"
          form={
            <ScheduleForm
              onUpdate={handleSubmit}
              edit
              initialData={scheduler}
            />
          }
        />
      </IonCardContent>
    </IonCard>
  );
};

export default ScheduleItem;
