import { IonButton, IonSkeletonText, IonText } from "@ionic/react";

type SkeletonCardButtonProps = {};

const SkeletonCardButton = (props: SkeletonCardButtonProps) => {
  return (
    <IonButton
      fill="solid"
      color="light"
      disabled
      style={{ width: "100%", height: "128px" }}
    >
      <div
        className="flex flex-column justify-between items-center "
        style={{ width: "100%", height: "100%", padding: "1.1em 0" }}
      >
        <div className="w-full truncat txt-left">
          <IonSkeletonText animated style={{ width: "40%" }}></IonSkeletonText>
        </div>
        <div className="h2 bold">
          <IonSkeletonText animated style={{ width: "100px" }}></IonSkeletonText>
        </div>
        <div className="w-full flex justify-between">
          <IonText color={"medium"}>
            <IonSkeletonText
              animated
              style={{ width: "20px" }}
            ></IonSkeletonText>
          </IonText>
          <IonText color="medium">
            <IonSkeletonText
              animated
              style={{ width: "20px" }}
            ></IonSkeletonText>
          </IonText>
        </div>
      </div>
    </IonButton>
  );
};

export default SkeletonCardButton;
