import { EntityId } from "../common/types/entity";

const KEY_QUICKVIEW = "quickview";

export interface IQuickViewItemConfig {
  entity: EntityId;
  key: string;
  name: string;
}
export const setLocalQuickview = (quickviewItems: IQuickViewItemConfig[]) => {
  localStorage.setItem(KEY_QUICKVIEW, JSON.stringify(quickviewItems));
};
export const useQuickview = () => {
  const quickviewItem = localStorage.getItem(KEY_QUICKVIEW);
  if (quickviewItem === null) return [];

  return JSON.parse(quickviewItem) as IQuickViewItemConfig[];
};
