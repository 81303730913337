import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";

/* Bascss */
import "basscss/css/basscss.css";

/* Utilities */
import "./theme/index.css";

import { useEffect, useState } from "react";
import { appPages, navigationBarPages } from "./const/app-pages";
import LoginPage from "./pages/LoginPage";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import { useIsAuthenticated } from "./services/authentication/useIsAuthenticated";
import { routes } from "./config/routes";

setupIonicReact();

type AppState = "loading" | "unauthenticated" | "authenticated";

const App: React.FC = () => {
  const [appState, setAppState] = useState<AppState>("loading");
  const isAuthenticated = useIsAuthenticated();

  const onLoginSuccess = () => {
    setAppState("authenticated");
  };

  useEffect(() => {
    if (isAuthenticated) {
      setAppState("authenticated");
    } else {
      setAppState("unauthenticated");
    }
  }, [isAuthenticated]);

  if (appState === "loading") {
    return <SplashScreen />;
  }

  if (appState === "unauthenticated") {
    return <LoginPage onSuccess={onLoginSuccess} />;
  }

  const mainRoutes = appPages.map((route) => (
    <Route key={route.url} exact path={route.url} component={route.page} />
  ));

  const settingRoutes = routes.settings.map((route) => (
    <Route key={route.path} path={route.path} component={route.component} />
  ));

  const navigationBarButtons = navigationBarPages.map((page) => (
    <IonTabButton tab={page.tab} href={page.url} key={page.tab}>
      <IonIcon icon={page.icon} />
      <IonLabel>{page.title}</IonLabel>
    </IonTabButton>
  ));

  console.log(appState);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/login" component={LoginPage} />
            {mainRoutes}
            {settingRoutes}
          </IonRouterOutlet>

          <IonTabBar slot="bottom">{navigationBarButtons}</IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
