import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { TB_URL } from "../const/thingsboard";
import { clearLocalTokens, getLocalTokens, logout, refreshTokens, setLocalTokens } from "../services/authentication/authentication";

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config) => {
        const tokens = getLocalTokens();
        config.headers = {
            "X-Authorization": `Bearer ${tokens?.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        config.baseURL = TB_URL;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const tokens = getLocalTokens();
            if (tokens) {
                try {
                    const newTokensResponse = await refreshTokens(tokens?.refreshToken);
                    const { data: newTokens } = newTokensResponse;
                    setLocalTokens(newTokens)
                    axios.defaults.headers.common["Authorization"] = "Bearer " + newTokens.token;
                } catch (error: any) {
                    if (error?.response?.status === 401) {
                        clearLocalTokens();
                    }
                }
            }
            return axiosApiInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export { axiosApiInstance };
export const fetcher = (url: string, configs?: AxiosRequestConfig) =>
    axiosApiInstance.get(url, configs).then((res) => res.data);