const SCHEDULER_LOCAL_STORAGE_KEY = "schedulers";

export const AVAILABLE_ACTIONS = {
  send_notitication: "Gửi thông báo",
  control_on: "Điều khiển bật",
  control_off: "Điều khiển tắt",
};

export const AVAILABLE_EVERY_UNIT = {
  hour: "Giờ",
  day: "Ngày",
};

export type AvailableActions = keyof typeof AVAILABLE_ACTIONS;
export type AvailableEveryUnit = keyof typeof AVAILABLE_EVERY_UNIT;

export type Scheduler = {
  id?: string;
  name?: string;
  runAt?: string; // work with every day. Format: 12:00
  every?: number;
  everyUnit?: AvailableEveryUnit;
  duringMinutes?: number; // minutes
  action: AvailableActions;
  deviceId?: string;
  relayGpio?: number;
  enable?: boolean;
};

/**
 *
 * @returns id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
 */
const guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

export const getAll = () => {
  const schedulersLocalData =
    localStorage.getItem(SCHEDULER_LOCAL_STORAGE_KEY) || "[]";
  const schedulers = JSON.parse(schedulersLocalData) as Scheduler[];
  return schedulers;
};

export const get = (id: string) => {
  return getAll().find((scheduler) => scheduler.id === id);
};

export const post = (data: Scheduler) => {
  data.id = guid();

  const schedulers = getAll() || [];

  schedulers.push(data);

  localStorage.setItem(SCHEDULER_LOCAL_STORAGE_KEY, JSON.stringify(schedulers));
};

export const put = (data: Scheduler) => {
  const schedulers = getAll() || [];

  const scheduler = schedulers.find((item) => item.id === data.id);
  const index = schedulers.findIndex((item) => item.id === data.id);

  if (scheduler) {
    const newScheduler = { ...scheduler, ...data };
    schedulers[index] = newScheduler;
    localStorage.setItem(
      SCHEDULER_LOCAL_STORAGE_KEY,
      JSON.stringify(schedulers)
    );
  }
};

export const remove = (id: string) => {
  const schedulers = getAll() || [];

  const newSchedulers = schedulers.filter((item) => item.id !== id);

  localStorage.setItem(
    SCHEDULER_LOCAL_STORAGE_KEY,
    JSON.stringify(newSchedulers)
  );
};
