import { axiosApiInstance } from "../../utils/fetcher";

/**
 * Ref: https://hmpiot.com/swagger-ui/#/rpc-v-2-controller/handleOneWayDeviceRPCRequestUsingPOST_1
 *
 * @params
 * Server-side RPC structure
 * The body of server-side RPC request consists of multiple fields:
 * - method - mandatory, name of the method to distinct the RPC calls. For example, "getCurrentTime" or "getWeatherForecast". The value of the parameter is a string.
 * - params - mandatory, parameters used for processing of the request. The value is a JSON. Leave empty JSON "{}" if no parameters needed.
 * - timeout - optional, value of the processing timeout in milliseconds. The default value is 10000 (10 seconds). The minimum value is 5000 (5 seconds).
 * - expirationTime - optional, value of the epoch time (in milliseconds, UTC timezone). Overrides timeout if present.
 * - persistent - optional, indicates persistent RPC. The default value is "false".
 * - retries - optional, defines how many times persistent RPC will be re-sent in case of failures on the network and/or device side.
 * - additionalInfo - optional, defines metadata for the persistent RPC that will be added to the persistent RPC events.
 *
 * @return
 * RPC Result
 * In case of persistent RPC, the result of this call is 'rpcId' UUID.
 * In case of lightweight RPC, the result of this call is either 200 OK if the message was sent to device, or 504 Gateway Timeout if device is offline.
 *
 * Available for users with 'TENANT_ADMIN' or 'CUSTOMER_USER' authority.
 */
export const sendOneWayRPCRequest = (
  deviceId: string,
  method: string,
  params: Record<string, any>,
  timeout?: number,
  expirationTime?: number,
  persistent?: boolean,
  retries?: number,
  additionalInfo?: Record<string, any>
) => {
  const api = `/api/rpc/oneway/${deviceId}`;
  const data: Record<string, any> = {
    method: method,
    params: params,
    persistent: persistent || false,
    timeout: timeout || 5000,
  };
  if (expirationTime) {
    data["expirationTime"] = expirationTime;
  }
  if (persistent) {
    data["persistent"] = persistent;
  }
  if (retries) {
    data["retries"] = retries;
  }
  if (additionalInfo) {
    data["additionalInfo"] = additionalInfo;
  }

  return axiosApiInstance.post(api, data);
};
export const sendTwoWayRPCRequest = (
  deviceId: string,
  method: string,
  params: Record<string, any>,
  timeout?: number,
  expirationTime?: number,
  persistent?: boolean,
  retries?: number,
  additionalInfo?: Record<string, any>
) => {
  const api = `/api/rpc/twoway/${deviceId}`;
  const data: Record<string, any> = {
    method: method,
    params: params,
    persistent: persistent || false,
    timeout: timeout || 5000,
  };
  if (expirationTime) {
    data["expirationTime"] = expirationTime;
  }
  if (persistent) {
    data["persistent"] = persistent;
  }
  if (retries) {
    data["retries"] = retries;
  }
  if (additionalInfo) {
    data["additionalInfo"] = additionalInfo;
  }

  return axiosApiInstance.post(api, data);
};
