import {
  IonButton,
  IonDatetime,
  IonInput,
  IonLabel,
  IonRange,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonAlert,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { IDevice } from "../../common/types/device";
import {
  AvailableActions,
  AvailableEveryUnit,
  AVAILABLE_ACTIONS,
  Scheduler,
} from "../../services/schedulers";
import { Relay } from "../../services/useRelays";
import DeviceSelect from "./DeviceSelect";
import RelaySelect from "./RelaySelect";

type ScheduleFormProps = {
  initialData?: Scheduler;
  onCreate?: (scheduler: Scheduler) => void;
  onUpdate?: (scheduler: Scheduler) => void;
  onRemove?: (id: string) => void;
  edit?: boolean;
};

const ScheduleForm = ({
  initialData,
  onCreate,
  onUpdate,
  onRemove,
  edit,
}: ScheduleFormProps) => {
  const [presentAlert] = useIonAlert();

  const [runAt, setRunAt] = useState<string | undefined | null>(
    initialData?.runAt || "12:00"
  );
  const [every, setEvery] = useState(initialData?.every || 1);
  const [everyUnit, setEveryUnit] = useState<"hour" | "day">(
    initialData?.everyUnit || "hour"
  );
  const [action, setAction] = useState<AvailableActions>(
    initialData?.action || "send_notitication"
  );
  const [deviceId, setDeviceId] = useState<string | undefined>(
    initialData?.deviceId
  );
  const [relayGpio, setRelayGpio] = useState<number | undefined>(
    initialData?.relayGpio
  );
  const [duringMinutes, setDuringMinutes] = useState(
    initialData?.duringMinutes || 0
  );

  const onSelectDevice = (value: IDevice | undefined) => {
    setDeviceId(value?.id.id);
  };
  const onSelectRelay = (relay?: Relay) => {
    setRelayGpio(relay?.gpio);
  };

  const handleSubmit = () => {
    const scheduler: Scheduler = {
      id: initialData?.id,
      name: AVAILABLE_ACTIONS[action],
      runAt: runAt || undefined,
      every,
      everyUnit,
      action,
      deviceId,
      relayGpio,
      duringMinutes,
      enable: initialData?.enable !== undefined ? initialData.enable : true,
    };

    if (edit) {
      onUpdate && onUpdate(scheduler);
    } else {
      onCreate && onCreate(scheduler);
    }
  };

  const handleRemoveScheduler = () => {
    onRemove && onRemove(initialData?.id || "");
  };

  return (
    <form>
      <div className="ion-align-items-center ion-margin-vertical">
        <IonText>Lên lịch theo</IonText>

        <IonSegment
          value={everyUnit}
          onIonChange={(event) => {
            setEvery(1);
            setEveryUnit(event.detail.value as AvailableEveryUnit);
          }}
        >
          <IonSegmentButton value="day">
            <IonLabel>Ngày</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="hour">
            <IonLabel>Giờ</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>

      {everyUnit === "hour" ? (
        <div className="ion-align-items-center ion-margin-vertical">
          <IonText>Thực hiện mỗi</IonText>
          <IonText className="ion-margin">
            <span style={{ fontSize: "2em" }}>{every}</span>
            <span className="ion-margin-start">giờ</span>
          </IonText>
          <IonRange
            ticks={true}
            snaps={true}
            pin={true}
            min={1}
            max={12}
            value={every}
            onIonChange={(event) => setEvery(event.detail.value as number)}
          ></IonRange>
        </div>
      ) : null}

      {everyUnit === "day" ? (
        <>
          <div className="ion-align-items-center ion-margin-vertical">
            <IonText>Thực hiện mỗi</IonText>
            <IonText className="ion-margin">
              <span style={{ fontSize: "2em" }}>{every}</span>
              <span className="ion-margin-start">ngày</span>
            </IonText>
            <IonRange
              ticks={true}
              snaps={true}
              pin={true}
              min={1}
              max={30}
              value={every}
              onIonChange={(event) => setEvery(event.detail.value as number)}
            ></IonRange>
          </div>

          <div className="ion-align-items-center ion-margin-vertical">
            <IonText>Vào lúc</IonText>
            <IonDatetime
              presentation="time"
              onIonChange={(e) => setRunAt(e.detail.value)}
              value={runAt}
            ></IonDatetime>
          </div>
        </>
      ) : null}

      <div className="ion-align-items-center ion-margin-vertical">
        <IonText>Thực hiện</IonText>
        <IonSelect
          interface="action-sheet"
          cancelText="Hủy bỏ"
          placeholder="Chọn hành động"
          value={action}
          onIonChange={(event) => setAction(event.detail.value)}
        >
          {Object.keys(AVAILABLE_ACTIONS).map((key) => (
            <IonSelectOption value={key} key={key}>
              {AVAILABLE_ACTIONS[key as AvailableActions]}
            </IonSelectOption>
          ))}
        </IonSelect>
      </div>

      {action === "control_on" || action === "control_off" ? (
        <>
          <div className="ion-align-items-center ion-margin-vertical">
            <IonText>Chọn thiêt bị</IonText>
            <DeviceSelect value={deviceId} onChange={onSelectDevice} />
          </div>

          {deviceId ? (
            <div className="ion-align-items-center ion-margin-vertical">
              <IonText>Chọn công tắc</IonText>
              <RelaySelect
                value={relayGpio}
                onChange={onSelectRelay}
                deviceId={deviceId}
              />
            </div>
          ) : null}

          <div className="ion-align-items-center ion-margin-vertical">
            <IonText>Trong vòng (phút)</IonText>
            <IonInput
              type="number"
              value={duringMinutes}
              onIonChange={(e) =>
                setDuringMinutes(
                  e.detail.value ? Number.parseInt(e.detail.value) : 0
                )
              }
            />
          </div>
        </>
      ) : null}

      <IonButton
        expand="full"
        shape="round"
        className="ion-margin"
        type="button"
        onClick={handleSubmit}
      >
        Lưu hẹn giờ
      </IonButton>

      {edit ? (
        <IonButton
          expand="full"
          fill="clear"
          shape="round"
          className="ion-margin"
          type="button"
          onClick={() => {
            presentAlert({
              header: "Bạn chắc chắn muốn xóa hẹn giờ này?",
              buttons: [
                {
                  text: "Hủy bỏ",
                  role: "cancel",
                },
                {
                  text: "Xóa",
                  role: "confirm",
                  handler: () => handleRemoveScheduler(),
                },
              ],
            });
          }}
        >
          Xóa hẹn giờ
        </IonButton>
      ) : null}
    </form>
  );
};

export default ScheduleForm;

//TODO: Validate form
//TODO: Save to user setting to key: scheduler_setting attribute
