import { TsValue } from "../../../common/types/timeseries";
import { MetricConfigs } from "../../Devices/common/types/metric";

export const getMetricsByConfigs = (
  data: Record<string, TsValue[]>,
  configs: MetricConfigs
) => {
  const allDataKeys = Object.keys(data);
  const configValues = Object.values(configs);

  const returnValues = [];
  for (const config of configValues) {
    const exactKey = allDataKeys.find((k) =>
      k.includes(config.keyPattern || "")
    );

    if (exactKey === undefined) continue;

    const dataValue = data[exactKey];

    returnValues.push({
      value: dataValue[0].value,
      timestamp: dataValue[0].ts,
      metric: config,
    });
  }

  return returnValues;
};
