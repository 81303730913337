import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonText,
} from "@ionic/react";
import {
  flashOffOutline,
  flashOutline,
  thermometerOutline,
} from "ionicons/icons";
import { IDevice } from "../../common/types/device";
import { useAttributes } from "../../services/useTelemetry";
import { getAttributeValue } from "../../utils/attributes_helpers";
import RelayDevice from "./RelayDevice/RelayDevice";

interface IDeviceProps {
  device: IDevice;
}

const Device: React.FC<IDeviceProps> = ({ device }) => {
  const { attributes, isLoading, isError } = useAttributes(device.id);
  if (isLoading) return <IonSpinner />;
  if (isError) return <IonIcon slot="start" icon={flashOffOutline} />;
  const isActive = getAttributeValue(attributes || [], "active");

  return (
    <IonItem className="ion-no-padding">
      <IonIcon icon={thermometerOutline} />
      <IonLabel>{device.name}</IonLabel>
      {(isActive && isActive.value) === true ? (
        <IonIcon icon={flashOutline} />
      ) : (
        <IonIcon icon={flashOffOutline} />
      )}
    </IonItem>
  );
};

interface IDeviceListProps {
  devices: IDevice[];
}
const DeviceList: React.FC<IDeviceListProps> = ({ devices }) => {
  if (devices.length === 0) {
    return (
      <div>
        <IonText>You don't have any device right now</IonText>
      </div>
    );
  }
  const deviceItems = devices.map((device) => (
    <Device device={device} key={device.id.id} />
  ));

  const relayDevices = devices.map((device) => (
    <RelayDevice deviceId={device.id.id} key={device.id.id} />
  ));
  return (
    <>
      <IonList className="padding-edge">{deviceItems}</IonList>
      <h2>Relay devices</h2>
      <IonList>{relayDevices}</IonList>
    </>
  );
};
export { Device, DeviceList };
