import { useToken } from "./useToken";

/**
 * Parse token string to JWT
 *
 * @param token
 * @returns
 */
export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/**
 * Check whether JWT Token has expired
 *
 * @param token JWT Token to check
 * @returns If token has expired return true, otherwise return false
 */
export const tokenHasExpired = (token: string) => {
  const jwt = parseJwt(token);
  if (!jwt || !jwt.exp) return true;
  return jwt.exp < Date.now() / 1000;
};

export const useIsAuthenticated = () => {
  const { token } = useToken();
  if (!token) return false;

  return !tokenHasExpired(token);
};
