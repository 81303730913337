import axios from "axios";
import { TB_URL } from "../../const/thingsboard";
import { axiosApiInstance } from "../../utils/fetcher";
import { LOCAL_TOKENS_KEY } from "./const";

export interface ITokens {
  token: string;
  refreshToken: string;
}

export const isAuthed = () => {
  const tokens = getLocalTokens();
  if (tokens === undefined) {
    return false;
  }
  return true;
};

export const login = (username: string, password: string) => {
  return axios.post<ITokens>(
    "/api/auth/login",
    {
      username,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      baseURL: TB_URL,
    }
  );
};
export const logout = () => {
  return axiosApiInstance.post("/api/auth/logout");
};

export const refreshTokens = (refreshToken: string) => {
  return axios.post<ITokens>(
    "/api/auth/token",
    {
      refreshToken,
    },
    {
      baseURL: TB_URL,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const setLocalTokens = (tokens: ITokens) => {
  localStorage.setItem(LOCAL_TOKENS_KEY, JSON.stringify(tokens));
};

export const getLocalTokens = () => {
  const tokens = localStorage.getItem(LOCAL_TOKENS_KEY);
  if (tokens === null) return undefined;
  return JSON.parse(tokens) as ITokens;
};

export const clearLocalTokens = () => {
  localStorage.removeItem(LOCAL_TOKENS_KEY);
};

export const checkAuthenticationStatus = async () => {
  const tokens = getLocalTokens();
  if (tokens === undefined) {
    return false;
  }
  return true;
};



