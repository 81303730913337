import { IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { ReactNode } from "react";
import { useLocation } from "react-router";
import { appPages } from "../../const/app-pages";

type HeaderProps = {
  name?: string;
  buttonStart?: ReactNode;
  buttonEnd?: ReactNode;
};
const Header: React.FC<HeaderProps> = (props) => {
  const location = useLocation();
  const currentPage = appPages.find((page) => page.url === location.pathname);
  const name = props.name ? props.name : currentPage && currentPage.title;

  return (
    <IonHeader translucent>
      <IonToolbar>
        {props.buttonStart ? props.buttonStart : null}
        <IonTitle>{name}</IonTitle>
        {props.buttonEnd ? props.buttonEnd : null}
      </IonToolbar>
    </IonHeader>
  );
};
export const ContentHeader: React.FC = () => {
  const location = useLocation();
  const currentPage = appPages.filter((page) => page.url === location.pathname);
  const name = currentPage.length > 0 ? currentPage[0].title : "";

  return (
    <IonHeader collapse="condense">
      <IonToolbar>
        <IonTitle size="large">{name}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
export default Header;
