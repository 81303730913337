import { IonButton, IonIcon, IonSpinner, useIonToast } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import type { FC } from 'react';
import { EntityId } from '../../common/types/entity';
import { useQuickview } from '../../services/useQuickview';
import { useLatestTimeseries } from '../../services/useTelemetry';
import './QuickView.css';


interface QuickViewProps {
    entity: EntityId
    name: string
    key: string
    unit?: string
}

const QuickView: FC<QuickViewProps> = ({ entity, name, key, unit }) => {
    const { latestTimeseries, isLoading, isError } = useLatestTimeseries(entity);
    const loadingContent = isLoading ? <IonSpinner /> : null;
    const errorContent = isError ? <div>Error</div> : null;
    let latestTimeseriesContent = <IonSpinner />;
    if (latestTimeseries !== undefined) {
        const value = latestTimeseries[key] ? latestTimeseries[key][0] : { value: '--' };
        latestTimeseriesContent = <>
            <div className={`quickview-container`}>
                <div className='quickview-label'>{name}</div>
                <div className='quickview-value'>{value.value}</div>
                <div className='quickview-unit'>{unit}</div>
            </div>
        </>
    }
    return (
        <div>
            {loadingContent}
            {errorContent}
            {latestTimeseriesContent}
        </div>

    );
}

interface QuickViewListProps {
}
const QuickViewList: FC<QuickViewListProps> = () => {
    const quickviewItems = useQuickview();
    const [present] = useIonToast();

    const itemsContent = quickviewItems.map((item) => <QuickView {...item} key={item.key} />)
    return (
        <div>
            <div className='flex justify-between items-center'>
                <h3>Xem nhanh</h3>
                <IonButton fill='clear' onClick={() => present('Coming soon', 3000)}>
                    <IonIcon icon={addOutline} />
                </IonButton>
            </div>
            <div className='quickview-list-container'>
                <div className={`quickview-list`} >
                    {itemsContent}
                </div>
            </div>
        </div>
    )
}

export { QuickView, QuickViewList };
