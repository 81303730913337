import { format, fromUnixTime } from "date-fns";
import { Attribute } from "../common/types/attribute";

export const getAttributeValue = (attributes: Attribute[], key: string) => {
  const kv = attributes.filter((attr) => attr.key === key);
  if (kv.length > 0) return kv[0];
  return undefined;
};

export const isActive = (attributes: Attribute[]) => {
  return getAttributeValue(attributes, "active");
};

export const formatDateTime = (value: number) => {
  const unixTime = Math.floor(value / 1000);
  return format(fromUnixTime(unixTime), 'dd/MM/yyyy HH:mm')
};

type AttributeValue = {
  name: string;
  isDateTime?: boolean;
};
const SUPPORTED_ATTRIBUTES: Record<string, AttributeValue> = {
  active: {
    name: "Đang Online",
  },
  inactivityAlarmTime: {
    name: "Thời gian báo Offline",
    isDateTime: true,
  },
  lastActivityTime: {
    name: "Thời gian Online lần cuối",
    isDateTime: true,
  },
  lastConnectTime: {
    name: "Thời gian kết nối lên server lần cuối",
    isDateTime: true,
  },
  lastDisconnectTime: {
    name: "Thời gian mất kết nối với server lần cuối",
    isDateTime: true,
  },
} as const;
type SupportedAttributeKeyName = keyof typeof SUPPORTED_ATTRIBUTES;

export const formatAttribute = (
  attribute: Attribute
): { key: string; value: string; name: string; lastUpdateTs: string } => {
  const { key, value, lastUpdateTs } = attribute;
  const supportedAttribute = Object.keys(SUPPORTED_ATTRIBUTES).includes(key)
    ? SUPPORTED_ATTRIBUTES[key as SupportedAttributeKeyName]
    : undefined;
  return {
    key: key,
    name: supportedAttribute ? supportedAttribute.name : key,
    value:
      supportedAttribute && supportedAttribute.isDateTime
        ? formatDateTime(value)
        : value.toString(),
    lastUpdateTs: formatDateTime(lastUpdateTs.valueOf()),
  };
};
