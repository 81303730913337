import { MetricConfigs } from "../common/types/metric";

export const LEADTEC_WATER_SENSOR_METRICS: MetricConfigs = {
  ph: {
    name: "pH",
    min: 0,
    max: 14,
    unit: "",
    keyPattern: "_ph",
  },
  do: {
    name: "DO",
    unit: "mg",
    keyPattern: "_do",
  },
  orp: {
    name: "ORP",
    unit: "",
    keyPattern: "_orp",
  },
  temperature: {
    name: "Nhiệt độ",
    unit: "°C",
    keyPattern: "_temperature",
  },
  salinity: {
    name: "Salinity",
    unit: "",
    keyPattern: "_sal",
  },
  ec: {
    name: "EC",
    unit: "",
    keyPattern: "_ec",
  },
};
