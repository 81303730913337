import { IonSelect, IonSelectOption } from "@ionic/react";
import { IDevice } from "../../common/types/device";
import { useDevices } from "../../services/hooks/device/useDevices";

type DeviceSelectProps = {
  value?: string;
  onChange: (value: IDevice | undefined) => void;
};

const DeviceSelect = ({ value, onChange }: DeviceSelectProps) => {
  const { data: devices } = useDevices();

  const handleChangeDevice = (deviceId: string) => {
    const device = devices?.data.find((item) => item.id.id === deviceId);
    onChange(device);
  };

  if (!devices || !devices.data) {
    return (
      <IonSelect
        interface="action-sheet"
        value={value}
        onIonChange={(e) => handleChangeDevice(e.detail.value)}
        disabled
        placeholder="Không có thiết bị"
      ></IonSelect>
    );
  }

  return (
    <IonSelect
      interface="action-sheet"
      value={value}
      onIonChange={(e) => handleChangeDevice(e.detail.value)}
    >
      {devices.data.map((device) => (
        <IonSelectOption value={device.id.id} key={device.id.id}>
          {device.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

export default DeviceSelect;
