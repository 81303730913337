import {
  IonButton,
  IonContent,
  IonIcon,
  IonList,
  IonLoading,
  IonPage,
  useIonToast,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import _ from "lodash";
import Assistant from "../components/Assistant/Assistant";
import { Container } from "../components/Container/Container";
import RelayDevice from "../components/Device/RelayDevice/RelayDevice";
import LeadtecWaterSensorLatestValue from "../components/Devices/LeadtecWaterSensor/LatestValues";
import Header from "../components/Header/Header";
import { QuickViewList } from "../components/QuickView/QuickView";
import { useDevices } from "../services/hooks/device/useDevices";
import { useUser } from "../services/useUser";
import { capitalizeFirstLetter } from "../utils/string_helpers";
import "./DashboardPage.css";

const DashboardPage: React.FC = () => {
  const [present] = useIonToast();
  const { user, isLoading: isLoadingUser, isError: isErrorUser } = useUser();
  const {
    data: devices,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
  } = useDevices();

  const loadingContent =
    (isLoadingUser || isLoadingDevices) && (!isErrorUser || !isErrorDevices) ? (
      <IonLoading
        isOpen={isLoadingUser || isLoadingDevices}
        message={"Loading..."}
      />
    ) : null;

  const groupByTypeDevices = _.groupBy(devices?.data, (device) => device.type);

  const devicesContent = Object.keys(groupByTypeDevices)
    .sort()
    .map((key) => {
      const devicesByKey = groupByTypeDevices[key];
      return (
        <IonList key={key}>
          {key !== "default" && <h4>{capitalizeFirstLetter(key)}</h4>}

          {devicesByKey.map((device) => {
            if (key === "Leadtec Water Sensor") {
              return (
                <LeadtecWaterSensorLatestValue
                  deviceId={device.id.id}
                  key={device.id.id}
                  name={device.name}
                />
              );
            } else if (key === "relay") {
              return <RelayDevice deviceId={device.id.id} key={device.id.id} />;
            } else {
              return null;
            }
          })}
        </IonList>
      );
    });
  return (
    <IonPage>
      <Header />

      <IonContent fullscreen>
        {loadingContent}
        <Container>
          <QuickViewList />
        </Container>
        <Container>
          <Assistant />
        </Container>
        <Container>
          <div className="flex justify-between items-center">
            <h3>Giá trị mới nhất</h3>
            <IonButton
              fill="clear"
              onClick={() => present("Coming soon", 3000)}
            >
              <IonIcon icon={addOutline} />
            </IonButton>
          </div>
          {devicesContent}
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default DashboardPage;
