import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const NotificationSetting: React.FC = () => {
  console.log("NotificationSetting");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Thông báo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonListHeader>
          <IonLabel>Cài đặt thông báo</IonLabel>
        </IonListHeader>
        <IonList></IonList>
      </IonContent>
    </IonPage>
  );
};

export default NotificationSetting;
