import { IonButton, IonText } from "@ionic/react";
import { format, fromUnixTime } from "date-fns";
import type { FC } from "react";

interface CardButtonProps {
  name: string;
  value: number | string | boolean | object | undefined;
  minValue?: number;
  maxValue?: number;
  unit?: string;
  showUnit?: boolean;
  showPercent?: boolean;
  lastUpdateTs?: number;
}

const CardButton: FC<CardButtonProps> = ({
  name,
  value,
  minValue,
  maxValue,
  unit,
  showUnit,
  showPercent,
  lastUpdateTs,
}) => {
  const content = value;
  const datetime = lastUpdateTs ? fromUnixTime(lastUpdateTs / 1000) : null;
  return (
    <IonButton
      fill="solid"
      color="light"
      style={{ width: "100%", height: "128px" }}
    >
      <div
        className="flex flex-column justify-between items-center "
        style={{ width: "100%", height: "100%", padding: "1.1em 0" }}
      >
        <div className="w-full truncat txt-left">
          {name}
          {showUnit && (
            <IonText color="medium" style={{ marginLeft: "0.25em" }}>
              {unit || ""}
            </IonText>
          )}
        </div>
        <div className="h2 bold">{content}</div>
        <div className="w-full flex justify-between">
          <IonText color={"medium"}>
            {datetime ? format(datetime, "HH:mm") : "--"}
          </IonText>
          <IonText color="medium">
            {datetime ? format(datetime, "dd/MM") : "--"}
          </IonText>
        </div>
      </div>
    </IonButton>
  );
};
export default CardButton;
