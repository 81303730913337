const DEVICE_SETTING_LOCAL_KEY = "device";

const DEFAULT_SETTINGS = {
  relay: {
    names: {
      GPIO_13: "Relay 13",
      GPIO_27: "Relay 27",
      GPIO_26: "Relay 26",
      GPIO_18: "Relay 18",
      GPIO_25: "Relay 25",
      GPIO_14: "Relay 14",
      GPIO_33: "Relay 33",
      GPIO_2: "Relay 2",
      GPIO_19: "Relay 19",
      GPIO_22: "Relay 22",
      GPIO_21: "Relay 21",
      GPIO_32: "Relay 32",
      GPIO_23: "Relay 23",
    },
  },
  leadtec_sensor: {},
};

export type SupportedDeviceSettingType = keyof typeof DEFAULT_SETTINGS;
export type RelayDeviceSettingKey =
  keyof typeof DEFAULT_SETTINGS["relay"]["names"];
export type RelayDeviceSettingOption = typeof DEFAULT_SETTINGS["relay"];
export type LeadtecDeviceSettingOption =
  typeof DEFAULT_SETTINGS["leadtec_sensor"];

function useDeviceSettingLocal<T extends SupportedDeviceSettingType>(
  id: string,
  type: SupportedDeviceSettingType
) {
  const localStorageKey = `${DEVICE_SETTING_LOCAL_KEY}.${id}`;

  const setDeviceSettingLocal = (setting: typeof DEFAULT_SETTINGS[T]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(setting));
  };

  const settingData = localStorage.getItem(localStorageKey);

  if (settingData === null) {
    // Save default setting to local storage and return the default
    const defaultSetting = DEFAULT_SETTINGS[type as T];
    if (Object.keys(DEFAULT_SETTINGS).includes(type)) {
      localStorage.setItem(localStorageKey, JSON.stringify(defaultSetting));
    }
    return { setting: defaultSetting, update: setDeviceSettingLocal };
  }
  const setting = JSON.parse(settingData) as typeof DEFAULT_SETTINGS[T];
  return { setting: setting, update: setDeviceSettingLocal };
}

export default useDeviceSettingLocal;
