import { IonButton, IonIcon, useIonToast } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import type { FC } from 'react';

interface AssistantProps { }

const Assistant: FC<AssistantProps> = ({ }) => {
    const [present] = useIonToast();
    return (
        <div>
            <div className='flex justify-between items-center'>
                <h3>Trợ lý</h3>
                <IonButton fill='clear' onClick={() => present('Coming soon', 3000)}>
                    <IonIcon icon={addOutline} />
                </IonButton>
            </div>
        </div>
    );
}
export default Assistant;
