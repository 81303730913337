import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  useIonRouter,
} from "@ionic/react";
import _ from "lodash";
import { useState } from "react";
import useDeviceSettingLocal, {
  RelayDeviceSettingOption,
} from "../../services/hooks/device/useDeviceSettingLocal";

const RELAY_TYPE = "relay";

type RelayDeviceSettingDetailProps = {
  id: string;
};

const RelayDeviceSettingDetail = ({ id }: RelayDeviceSettingDetailProps) => {
  const router = useIonRouter();
  const { setting, update } = useDeviceSettingLocal<"relay">(id, RELAY_TYPE);
  const [newSetting, setNewSetting] =
    useState<RelayDeviceSettingOption>(setting);
  const onChangeName = (key: keyof typeof setting["names"], value: string) => {
    const updatedSetting = _.cloneDeep(newSetting);
    updatedSetting["names"][key] = value;
    setNewSetting(updatedSetting);
  };
  return (
    <>
      <IonList>
        {_.map(
          newSetting.names,
          (value: string, key: keyof typeof setting["names"]) => {
            return (
              <IonItem key={key} lines="none">
                <IonLabel className="txt-bold">{key}</IonLabel>
                <IonInput
                  type="text"
                  value={value}
                  onIonChange={(event) =>
                    onChangeName(key, event.detail.value || "")
                  }
                />
              </IonItem>
            );
          }
        )}
      </IonList>
      <IonButton expand="block" onClick={() => update(newSetting)}>
        Lưu cài đặt
      </IonButton>
      <IonButton
        expand="block"
        fill="clear"
        routerDirection="back"
        onClick={() => router.goBack()}
      >
        Quay lại
      </IonButton>
    </>
  );
};

export default RelayDeviceSettingDetail;
