import {
  IonContent,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonText,
} from "@ionic/react";
import _ from "lodash";
import { DeviceList } from "../components/Device/Device";
import Header, { ContentHeader } from "../components/Header/Header";
import { useDevices } from "../services/hooks/device/useDevices";
import { useUser } from "../services/useUser";

const DevicesPage: React.FC = () => {
  const { user, isLoading: isLoadingUser, isError: isErrorUser } = useUser();
  const {
    data: devices,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
  } = useDevices();

  const loadingContent =
    (isLoadingUser || isLoadingDevices) && (!isErrorUser || !isErrorDevices) ? (
      <IonLoading
        isOpen={isLoadingUser || isLoadingDevices}
        message={"Loading..."}
      />
    ) : null;
  const errorContent =
    isErrorUser || isErrorDevices ? (
      <IonText>Error when getting user or devices</IonText>
    ) : null;

  const groupByTypeDevices = _.groupBy(devices?.data, (device) => device.type);

  const devicesContent = Object.keys(groupByTypeDevices).map((key) => {
    const devicesByKey = groupByTypeDevices[key];
    return (
      <IonList key={key}>
        <IonItemDivider className="ion-no-padding">
          <IonLabel className="padding-edge">{key}</IonLabel>
        </IonItemDivider>
        <DeviceList devices={devicesByKey} />
      </IonList>
    );
  });
  return (
    <IonPage>
      <Header />

      <IonContent fullscreen>
        <ContentHeader />
        {loadingContent}
        {errorContent}
        {devicesContent}
      </IonContent>
    </IonPage>
  );
};

export default DevicesPage;
