import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import { TAuthority } from "../common/types/authority";
import { EntityId } from "../common/types/entity";
import { fetcher } from "../utils/fetcher";

export type User = {
    id: EntityId;
    createdTime: number;
    tenantId: EntityId;
    customerId: EntityId;
    email: string;
    name: string;
    authority: TAuthority;
    firstName: string;
    lastName: string;
    additionalInfo: Record<string, any>;
    ownerId: EntityId;
}
export const useUser = (configs?: AxiosRequestConfig) => {
    const { data, error } = useSWR<User>([`/api/auth/user`, configs], fetcher);
    return {
        user: data,
        isLoading: !error && !data,
        isError: error,
    };
};
