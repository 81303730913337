type LogLevel = "debug" | "error" | "info";

/**
 * Use for connecting to external log service
 * 
 * @param message 
 * @param level 
 * @param params 
 */
export const log = (
  message: any,
  level: LogLevel = "debug",
  ...params: any[]
) => {
  switch (level) {
    case "debug":
      console.log(message, params);
      break;
    case "error":
      console.error(message, params);
      break;
    case "info":
      console.info(message, params);
      break;
  }
};
