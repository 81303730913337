import { EntityId } from "../../../common/types/entity";
import { Scope } from "../../../common/types";
import { axiosApiInstance } from "../../../utils/fetcher";

export type SaveEntityAttributesV2Params = {
  entityId: EntityId;
  scope: Scope;
  data: Record<string, any>;
};

const saveEntityAttributesV2 = ({
  entityId,
  scope,
  data,
}: SaveEntityAttributesV2Params) => {
  const { entityType, id } = entityId;
  const api = `/api/plugins/telemetry/${entityType}/${id}/attributes/${scope}`;
  return axiosApiInstance.post(api, data);
};

export default saveEntityAttributesV2;
