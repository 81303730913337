import { IonCol, IonGrid, IonRow } from "@ionic/react";
import _ from "lodash";
import { ReactNode } from "react";

export const gridGenerator = (nodes: ReactNode[], cols: number = 2) => {
  const chunkNodes = _.chunk(nodes, cols);

  const grids = chunkNodes.map((rows, rowIndex) => {
    return (
      <IonRow key={`row_${rowIndex}`}>
        {rows.map((node, colIndex) => {
          return <IonCol key={`col_${colIndex}`}>{node}</IonCol>;
        })}
      </IonRow>
    );
  });

  return <IonGrid>{grids}</IonGrid>;
};
