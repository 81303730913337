import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonLoading,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import _ from "lodash";
import useAttributes from "../../services/hooks/common/useGetAttributes";
import { formatAttribute } from "../../utils/attributes_helpers";

type BasicDeviceSettingDetailProps = {
  id: string;
};

const BasicDeviceSettingDetail = ({ id }: BasicDeviceSettingDetailProps) => {
  const router = useIonRouter();
  const { data, isError } = useAttributes({
    entityType: "DEVICE",
    id: id,
  });
  if (!data) {
    return <IonLoading isOpen={true} />;
  }
  if (isError) {
    return <IonText>Có lỗi xảy ra. Xin hãy quay lại sau.</IonText>;
  }
  const attributes = data.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  return (
    <>
      <IonList>
        {attributes.map((attribute) => {
          const { key, name, value, lastUpdateTs } = formatAttribute(attribute);
          return (
            <IonItem key={key} lines="none">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText color={"primary"}>{name}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {key === "active" ? (
                    <IonCol>
                      {value === "true" ? (
                        <IonText color={"success"}>ONLINE</IonText>
                      ) : (
                        <IonText color={"danger"}>OFFLINE</IonText>
                      )}
                    </IonCol>
                  ) : (
                    <IonCol>{value}</IonCol>
                  )}
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText color={"medium"}>
                      Cập nhật lúc: {lastUpdateTs}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          );
        })}
      </IonList>

      <IonButton
        expand="block"
        fill="solid"
        routerDirection="back"
        onClick={() => router.goBack()}
      >
        Quay lại
      </IonButton>
    </>
  );
};

export default BasicDeviceSettingDetail;
