import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import { useDevices } from "../../services/hooks/device";

  const DeviceList: React.FC = () => {
    const { data: devices } = useDevices();
    const deviceList = devices?.data.map((device) => (
      <IonItem
        lines="none"
        detail
        routerLink={`/settings/devices/${device.id.id}`}
        routerDirection="forward"
        key={device.id.id}
      >
        {device.name}
      </IonItem>
    ));
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton></IonBackButton>
            </IonButtons>
            <IonTitle>Thiết bị</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonListHeader>
            <IonLabel>Cài đặt Thiết bị</IonLabel>
            <IonButton routerLink="/settings/new-device">
              Thêm thiết bị
            </IonButton>
          </IonListHeader>
          <IonList>{deviceList}</IonList>
        </IonContent>
      </IonPage>
    );
  };

  export default DeviceList;
