import { EntityId } from "../../../common/types/entity";
import { Scope } from "../../../common/types/scope";
import useAttributes from "../common/useGetAttributes";

const useDeviceAttributes = (
  deviceId: string,
  scope?: Scope,
  keys?: string[]
) => {
  const entityId: EntityId = {
    entityType: "DEVICE",
    id: deviceId,
  };
  return useAttributes(entityId, scope, keys, {
    refreshInterval: 1000,
  });
};

export default useDeviceAttributes;
