import useSWR from "swr";
import { EntityId } from "../../../common/types/entity";
import { Scope } from "../../../common/types/scope";
import { fetcher } from "../../../utils/fetcher";
import { Attribute } from "./types";

const useAttributes = (
  entityId?: EntityId,
  scope?: Scope,
  keys?: string[],
  configs?: {
    refreshInterval: number;
  }
) => {
  const _scope = scope || "";
  const _keys = keys && keys.length > 0 ? `?keys=${keys.join(",")}` : "";

  const api = (entityId: EntityId) =>
    `/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/values/attributes/${_scope}${_keys}`;

  const { data, error } = useSWR<Attribute[]>(
    entityId !== undefined ? api(entityId) : null,
    fetcher,
    configs
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useAttributes;
