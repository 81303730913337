import { RouteComponentProps, StaticContext } from "react-router";
import AccountSetting from "../pages/Settings/AccountSetting";
import DeviceSetting from "../pages/Settings/DeviceSetting";
import DeviceSettingDetail from "../pages/Settings/DeviceSettingDetail";
import NewDeviceSetting from "../pages/Settings/NewDeviceSetting";
import NotificationSetting from "../pages/Settings/NotificationSetting";

type Route = {
  path: string;
  component:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | undefined;
};

type RoutePages = "settings";

type Routes = Record<RoutePages, Route[]>;

export const routes: Routes = {
  settings: [
    {
      path: "/settings/account",
      component: AccountSetting,
    },
    {
      path: "/settings/notification",
      component: NotificationSetting,
    },
    {
      path: "/settings/devices",
      component: DeviceSetting,
    },
    {
      path: "/settings/new-device",
      component: NewDeviceSetting,
    },
  ],
};
