import { AxiosRequestConfig } from "axios";

export type Relay = {
  deviceId: string;
  gpio: number;
  name: string;
};
export const useRelays = (
  deviceId: string,
  page?: number,
  pageSize?: number,
  configs?: AxiosRequestConfig
) => {
  const MAX_RELAY_GPIOS = 16;

  const relays: Relay[] = [];
  for (let i = 0; i < MAX_RELAY_GPIOS; i++) {
    relays.push({
      deviceId,
      gpio: i,
      name: `Công tắc ${i}`,
    });
  }

  return {
    relays: relays,
    isLoading: false,
    isError: false,
  };
};

export const useRelay = (deviceId: string, gpio: number) => {
  const relay: Relay = {
    deviceId: deviceId,
    gpio: gpio,
    name: `Công tắc ${gpio}`,
  };
  return {
    relay,
    isLoading: false,
    isError: false,
  };
};
