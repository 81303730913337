import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import { EntityId } from "../../../common/types/entity";
import { fetcher } from "../../../utils/fetcher";
import { User, useUser } from "../../useUser";

export interface Device {
  id: EntityId;
  createdTime: number;
  tenantId: EntityId;
  customerId?: EntityId;
  ownerId?: EntityId;
  name: string;
  type?: string;
  label?: string;
  deviceProfileId?: EntityId;
  deviceData?: {
    configuration: object;
    transportConfiguration: object;
  };
  firmwareId?: EntityId;
  softwareId?: EntityId;
  additionalInfo?: {};
  customerTitle?: string;
  customerIsPublic?: boolean;
  deviceProfileName?: string;
}

export type GetDevicesResponse = {
  data: Device[];
  totalPages: number;
  totalElements: number;
  hasNext: boolean;
}

const getDevicesApiByUserRole = (user: User) => {
  if (user.authority === "TENANT_ADMIN") {
    return "/api/tenant/devices";
  }
  return `/api/customer/${user.customerId.id}/devices`;
};
export const useDevices = (
  page?: number,
  pageSize?: number,
  configs?: AxiosRequestConfig
) => {
  const { user } = useUser();
  const mergedConfigs = {
    ...configs,
    ...{
      params: {
        page: page || 0,
        pageSize: pageSize || 25,
      },
    },
  };
  const { data, error } = useSWR<GetDevicesResponse>(
    [user ? getDevicesApiByUserRole(user) : null, mergedConfigs],
    fetcher
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useDevice = (deviceId?: string, configs?: AxiosRequestConfig) => {
  const { data, error } = useSWR<Device>(
    [deviceId ? `/api/device/${deviceId}` : null, configs],
    fetcher
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};
