import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { useDevice } from "../../services/hooks/device";
import BasicDeviceSettingDetail from "./BasicDeviceSettingDetail";
import RelayDeviceSettingDetail from "./RelayDeviceSettingDetail";

interface DeviceSettingDetailProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const DeviceSettingDetail: React.FC<DeviceSettingDetailProps> = ({ match }) => {
  const { data: device } = useDevice(match.params.id);

  if (!device) {
    return <IonText>Thiết bị không tồn tại</IonText>;
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>{device?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {device?.type === "relay" ? (
          <RelayDeviceSettingDetail id={device.id.id} />
        ) : (
          <BasicDeviceSettingDetail id={device.id.id} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default DeviceSettingDetail;
