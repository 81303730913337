import {
  IonActionSheet,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  useIonToast,
} from "@ionic/react";
import {
  person,
  logOut,
  notifications,
  close,
  trash,
  thermometer,
} from "ionicons/icons";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import Header from "../../components/Header/Header";
import {
  logout,
} from "../../services/authentication/authentication";

const SettingsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [present] = useIonToast();
  const [showLogoutActionSheet, setShowLogoutActionSheet] =
    useState<boolean>(false);
  const handleLogout = () => {
    logout()
      .then((res) => {
        localStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        present({
          message: "Đăng xuất không thành công",
          duration: 1500,
          position: "bottom",
        });
      });
  };
  return (
    <IonPage>
      <Header />

      <IonContent fullscreen>
        <IonList>
          <IonItem routerLink="/settings/account">
            <IonIcon icon={person} className="ion-margin-end" />
            <IonLabel>Tài khoản</IonLabel>
          </IonItem>
          <IonItem routerLink="/settings/devices">
            <IonIcon icon={thermometer} className="ion-margin-end" />
            <IonLabel>Thiết bị</IonLabel>
          </IonItem>
          <IonItem routerLink="/settings/notification">
            <IonIcon icon={notifications} className="ion-margin-end" />
            <IonLabel>Thông báo</IonLabel>
          </IonItem>
          <IonItem onClick={() => setShowLogoutActionSheet(true)}>
            <IonIcon icon={logOut} className="ion-margin-end" />
            <IonLabel>Đăng xuất</IonLabel>
          </IonItem>
        </IonList>

        <IonActionSheet
          isOpen={showLogoutActionSheet}
          onDidDismiss={() => setShowLogoutActionSheet(false)}
          buttons={[
            {
              text: "Đăng xuất",
              role: "destructive",
              icon: trash,
              data: {
                type: "delete",
              },
              handler: () => {
                handleLogout();
              },
            },
            {
              text: "Hủy bỏ",
              icon: close,
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
