import {
  chatbubble,
  gitNetwork,
  home,
  person,
  settings,
  time,
} from "ionicons/icons";
import AssistantPage from "../pages/Assistant/AssistantPage";
import DashboardPage from "../pages/DashboardPage";
import DevicesPage from "../pages/DevicesPage";
import ProfilePage from "../pages/ProfilePage";
import SchedulersPage from "../pages/Schedulers/SchedulersPage";
import SettingsPage from "../pages/Settings/SettingsPage";

export interface IAppPage {
  title: string;
  url: string;
  tab: string;
  icon: string;
  page: React.FC<any>;
}

export const appPages: IAppPage[] = [
  {
    title: "Biểu đồ",
    url: "/dashboard",
    tab: "dashboard",
    icon: home,
    page: DashboardPage,
  },
  {
    title: "Hẹn giờ",
    url: "/schedulers",
    tab: "schedulers",
    icon: time,
    page: SchedulersPage,
  },
  {
    title: "Thiết bị",
    url: "/devices",
    tab: "devices",
    icon: gitNetwork,
    page: DevicesPage,
  },
  // {
  //   title: "Trợ lý",
  //   url: "/assistant",
  //   tab: "assistant",
  //   icon: chatbubble,
  //   page: AssistantPage,
  // },
  {
    title: "Tài khoản",
    url: "/profile",
    tab: "profile",
    icon: person,
    page: ProfilePage,
  },
  {
    title: "Cài đặt",
    url: "/settings",
    tab: "settings",
    icon: settings,
    page: SettingsPage,
  },
];

export const navigationBarPages = appPages.filter(
  (page) => !["profile", "devices"].includes(page.tab)
);

