import { gridGenerator } from "../../utils/grid_generator";

type GridViewProps = {
  children: React.ReactNode[];
  column?: number;
};

const GridView = ({ children, column = 2 }: GridViewProps) => {
  const gridNodes = gridGenerator(children, column);

  return <>{gridNodes}</>;
};

export default GridView;
