/**
 * Document:
 *
 * 1. Relay status:
 *
 * Using Client attributes to determine the status of each relay. Ex: GPIO_13: 1 => ON, GPIO_13: 0 => OFF
 *
 * 2. Attributes:
 *
 * - Client attributes: GPIO_${GPIO PIN} : number [0 | 1]
 * - Server attributes: active: boolean, inactivityAlarmTime: number, lastConnectTime: number, lastDisconnectTime: number
 * - Shared attribtues: None
 *
 */

import { useDevice } from "../../../services/hooks/device/useDevices";
import useDeviceSettingLocal from "../../../services/hooks/device/useDeviceSettingLocal";
import GridView from "../../GridView/GridView";
import RelayToggle from "./RelayToggle";

interface RelayDeviceProps {
  deviceId: string;
}

const RelayDevice: React.FC<RelayDeviceProps> = ({ deviceId }) => {
  const { data: device } = useDevice(deviceId);
  const { setting } = useDeviceSettingLocal<"relay">(deviceId, "relay");
  const gpioAttributeKeys = Object.keys(setting.names);
  return (
    <div>
      <div>{device?.name || "---"}</div>
      <GridView>
        {gpioAttributeKeys?.sort().map((attributeKey) => (
          <RelayToggle
            deviceId={deviceId}
            relayKey={attributeKey}
            key={`${deviceId}.${attributeKey}`}
          />
        ))}
      </GridView>
    </div>
  );
};

export default RelayDevice;
