import React from "react";
import useDeviceLatestTimeseries from "../../../services/hooks/device/useDeviceLatestTimeseries";
import CardButton from "../../CardButton";
import GridView from "../../GridView/GridView";
import { getMetricsByConfigs } from "../../LatestTimeseries/utils/getMetricByConfigs";
import { LEADTEC_WATER_SENSOR_METRICS } from "./configs";

interface LeadtecWaterSensorLatestValueProps {
  deviceId: string;
  name?: string;
}

const LeadtecWaterSensorLatestValue: React.FC<
  LeadtecWaterSensorLatestValueProps
> = ({ deviceId, name }) => {
  const { data: latestTimeseries } = useDeviceLatestTimeseries(deviceId);

  const timeseries = latestTimeseries
    ? getMetricsByConfigs(latestTimeseries, LEADTEC_WATER_SENSOR_METRICS)
    : [];

  const nodes = timeseries.map((value) => {
    return (
      <CardButton
        name={value.metric.name}
        value={Number(value.value).toFixed(2)}
        minValue={value.metric.min}
        maxValue={value.metric.max}
        unit={value.metric.unit}
        showUnit={true}
        showPercent={true}
        key={value.metric.name}
        lastUpdateTs={value.timestamp as number}
      />
    );
  });

  if (nodes.length === 0) return null;

  return (
    <div>
      <h5>{name || "---"}</h5>
      <GridView>{nodes}</GridView>
    </div>
  );
};

export default LeadtecWaterSensorLatestValue;
