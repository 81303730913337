import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { useState } from "react";
import { login } from "../services/authentication/authentication";
import { LOCAL_TOKENS_KEY } from "../services/authentication/const";

import "./LoginPage.css";

export type LoginPageProps = {
  onSuccess?: () => void;
};

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = () => {
    if (!email || !password) {
      setError("Please enter email and password");
      return;
    }
    login(email, password)
      .then((res) => {
        if (res.status === 200) {
          const tokens = res.data;
          localStorage.setItem(LOCAL_TOKENS_KEY, JSON.stringify(tokens));
          if (props.onSuccess) {
            props.onSuccess();
          }
        } else {
          setError("Login failed");
          localStorage.setItem(LOCAL_TOKENS_KEY, JSON.stringify({}));
        }
      })
      .catch((error) => {
        setError("Login failed");
        localStorage.setItem(LOCAL_TOKENS_KEY, JSON.stringify({}));
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="login-form-wrapper login-page">
          <div className="login-form-container">
            <IonGrid className="login-form">
              <IonRow>
                <IonCol>
                  <IonText className="title">Login</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="stacked">Email</IonLabel>
                  <IonInput
                    type="email"
                    name="email"
                    value={email}
                    onIonChange={(event) => setEmail(event.detail.value!)}
                    onIonFocus={(event) => setError("")}
                    inputMode="email"
                    placeholder="user@hmpiot.com"
                    required
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel position="stacked">Password</IonLabel>
                  <IonInput
                    type="password"
                    name="password"
                    value={password}
                    onIonChange={(event) => setPassword(event.detail.value!)}
                    onIonFocus={(event) => setError("")}
                    required
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="full"
                    color={"primary"}
                    onClick={handleLogin}
                  >
                    Login
                  </IonButton>
                </IonCol>
              </IonRow>
              {(error && error.length) > 0 ? (
                <IonRow>
                  <IonCol>
                    <IonText className="error-message">{error}</IonText>
                  </IonCol>
                </IonRow>
              ) : null}
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
