import { IonSelect, IonSelectOption } from "@ionic/react";
import { Relay, useRelays } from "../../services/useRelays";

type RelaySelectProps = {
  deviceId: string;
  value?: number;
  onChange: (value: Relay | undefined) => void;
};

const RelaySelect = ({ deviceId, value, onChange }: RelaySelectProps) => {
  const { relays } = useRelays(deviceId);

  const handleChangeRelay = (gpio: number) => {
    const relay = relays.find((item) => item.gpio === gpio);
    onChange(relay);
  };

  if (!relays) {
    return (
      <IonSelect
        interface="action-sheet"
        value={value}
        onIonChange={(e) => handleChangeRelay(e.detail.value)}
        disabled
        placeholder="Thiết bị không hỗ trợ công tắc"
      ></IonSelect>
    );
  }

  return (
    <IonSelect
      interface="action-sheet"
      value={value}
      onIonChange={(e) => handleChangeRelay(e.detail.value)}
    >
      {relays.map((relay) => (
        <IonSelectOption value={relay.gpio} key={relay.name}>
          {relay.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

export default RelaySelect;
