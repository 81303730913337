import useAttributes from "../common/useGetAttributes";
import saveEntityAttributesV2 from "../../https/telemetry_controller/saveEntityAttributesV2";
import { EntityId } from "../../../common/types/entity";

const useUserSetting = (id?: EntityId, settingKeys?: string[]) => {
  const { data } = useAttributes(id, undefined, settingKeys);

  const update = (settingKey: string, obj: Record<string, any>) => {
    if (!id || !settingKey) return;

    return saveEntityAttributesV2({
      entityId: id,
      scope: "SERVER_SCOPE",
      data: {
        [settingKey]: obj,
      },
    });
  };

  return { data, update };
};

export default useUserSetting;
